import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { Container, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { H1, H3, Text } from '@system'
import PageContainer from '@system/page-container'

const useStyles = makeStyles((theme) => ({
  logo: {
    background: theme.palette.common.white,
    borderRadius: '12px',
    display: 'inline-block',
    padding: '20px',
    position: 'relative',
    '&>div>div': {
      display: 'none',
    },
  },
  columnContainer: {
    display: 'block',
    position: 'relative',

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  image: {
    '& .gatsby-image-wrapper': {
      [theme.breakpoints.down('sm')]: {
        maxWidth: '80%',
        margin: '0 auto',
      },

      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%',
      },
    },
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '50%',
      alignSelf: 'flex-end',
    },
  },
  headline: {
    color: theme.palette.secondary.main,
    margin: '16px 0px',
    fontWeight: theme.typography.fontWeightLight,
    [theme.breakpoints.up('sm')]: {
      margin: '24px 0px',
    },
    display: 'block',
  },
  description: {
    color: theme.palette.text.secondary,
    marginTop: '8px',
    display: 'block',
  },
  contentBox: {
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
  },
  stats: {
    display: 'flex',
    margin: '16px auto',
    [theme.breakpoints.up('sm')]: {
      margin: '24px 0px',
    },
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginTop: '8px',
    display: 'block',
    textAlign: 'center',
  },
  stat: {
    display: 'block',
    textAlign: 'center',
  },
}))

const ProductDetailsIntroModule = (props) => {
  const classes = useStyles()
  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <Box marginTop="-80px">
          <Container fixed>
            <Box className={classes.logo}>
              {renderBloks(props.blok.logoImage)}
            </Box>
          </Container>
          <Box className={classes.columnContainer}>
            <Container fixed>
              <Box className={classes.contentBox}>
                <H3 className={classes.headline}>{props.blok.title}</H3>
                <Text className={classes.description}>
                  {props.blok.description}
                </Text>
                <Box className={classes.stats}>
                  {props.blok.statCards.map((statBlok) => {
                    return (
                      <Box key={statBlok._uid} width="50%" mx={2}>
                        <H1
                          color="secondary"
                          className={classes.stat}
                          component="h2"
                        >
                          {statBlok.stat}
                        </H1>
                        <Text className={classes.subtitle}>
                          {statBlok.subtitle}
                        </Text>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </Container>
            <Box className={classes.image}>
              {!!props.blok.mockupImage && renderBloks(props.blok.mockupImage)}
            </Box>
          </Box>
        </Box>
      </PageContainer>
    </SbEditable>
  )
}

export default ProductDetailsIntroModule
